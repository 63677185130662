/* Customize the scrollbar width */
::-webkit-scrollbar {
  width: 4px; /* Adjust scrollbar width */
}

/* Customize the scrollbar track */
::-webkit-scrollbar-track {
  background: none; /* Track color */
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 10px; /* Rounded corners */
}

/* Customize the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Thumb color on hover */
}

html,
body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  /* width: 100%;
  height: 100%; */
}

.App {
  display: flex;
  font: medium;
  /* width: 100%;
  height: 100%; */
}

.content {
  flex-grow: 1;
  padding: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
